export default theme => ({
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  separator: {
    margin: 10,
  },
  title: {
    fontSize: 18,
    marginBottom: theme.spacing(2),
    textDecoration: 'underline',
  },
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  table: {
    marginTop: theme.spacing(3),
  },
  buttonShowProducts: {
    marginBottom: theme.spacing(2),
  },
  containerButton: {
    margin: '0 !impotant',
    width: '100% !important',
  },
  button: {
    height: 42,
  },
  modalForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    margin: 10,
    width: '100%',
    maxWidth: 820,
    paddingBottom: 30,
    textAlign: 'center',
  },
  modalReport: {
    padding: 30,
    width: '100%',
    maxWidth: 1240,
  },
  '@media(max-width: 760px)': {
    modalReport: {
      height: '100%',
      overflowY: 'auto',
    },
  },
  modalCloseContaier: {
    textAlign: 'right',
  },
  form: {
    '& .MuiFormControlLabel-root .MuiTypography-body1': {
      fontSize: 22,
    },
  },
});
