export default theme => ({
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  button: {
    height: 42,
  },
  createButton: {
    height: 53,
  },
  modalForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    margin: 10,
    width: '100%',
    maxWidth: 1240,
    paddingBottom: 30,
    textAlign: 'center',
  },
  timePicker: {
    width: '100%',
  },
  modalReport: {
    padding: 30,
    width: '100%',
    maxWidth: 1240,
  },
  '@media(max-width: 760px)': {
    modalContainer: {
      height: '100%',
    },
    modalReport: {
      height: '100%',
      overflowY: 'auto',
    },
  },
  modalCloseContaier: {
    textAlign: 'right',
  },
  header: {
    fontSize: 16,
    '& .MuiGrid-item': {
      paddingBottom: 10,
    },
  },
  title: {
    fontSize: 18,
    marginBottom: theme.spacing(2),
    textDecoration: 'underline',
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(2),
    },
  },
  closeButtonContainer: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(3),
    },
  },
  alertMui: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  alertDanger: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
  },
});
