import React, { useEffect, useState, useContext } from 'react';
import {
  withStyles,
  CircularProgress,
  Button,
} from '@material-ui/core';

import moment from 'moment';
import { withTranslation } from 'react-i18next';
import flow from 'lodash.flow';
import {
  Alert,
  Exceptions,
  Tabs,
} from 'admin-base-component-library';
import ErrorIcon from '@material-ui/icons/Error';
import UserContext from '../../context/UserContext';
import OrderBudgetReportGeneral from './OrderBudgetReportGeneral';
import OrderBudgetReportSupliers from './OrderBudgetReportSupliers';
import styles from './styles';
import palette from '../../theme/palette';
import {
  BUDGET_STATUS,
  getBudgetByIdWithResponse,
  getChildBudgetByIdWithResponse,
  getChildBudget,
  getBudgetById,
  getBudgetResponsesByBudgetId,
} from '../../services/budget';
import { getSuppliers } from '../../services/supplier';
import { getRestaurantByID } from '../../services/restaurant';
import {
  numberToFloat,
  numberToCurrency,
  TimestampToDateTime,
} from '../../services/Service';
import { USER_ROLES } from '../../services/user';
import OrderChildBudgetReportGeneral from './OrderChildBudgetReportGeneral';
import FranchiseesRestaurant from './Tabs/FranchiseesRestaurant';

const OrderBudgetReport = ({
  t,
  classes,
  history,
  match: { params },
}) => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [budget, setBudget] = useState();
  const [suppliersData, setSuppliersData] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [total, setTotal] = useState({});
  const [alertClickChangeSupplier, setAlertClickChangeSupplier] = useState(false);
  const [isParent, setIsParent] = useState(false);
  const [childBudgets, setChildBudgets] = useState([]);
  const [suppliersMinVal, setSuppliersMinVal] = useState(null);
  const [mainBudgetId, setMainBudgetId] = useState(null);
  const [mainBudgetStatus, setMainBudgetStatus] = useState(null);
  const [mainRestaurantId, setMainRestaurantId] = useState(null);
  const [franchiseesRestaurantView, setFranchiseesRestaurantView] = useState(false);
  const [type, setType] = useState(null);
  const [childHasDeficit, setChildHasDeficit] = useState(false);
  const [childDeficitSuppliers, setChildDeficitSuppliers] = useState([]);

  const getSuppliersAndBudget = async () => {
    const fetchSuppliers = await getSuppliers({});
    const supplierData = budget.suppliers.reduce((acc, supplier) => {
      const resultSupplier = fetchSuppliers.find(item => item.id === supplier);
      if (resultSupplier) {
        const bugetResponse = budget.responses.find(
          item => item.idSupplier === resultSupplier.id,
        );
        return [
          ...acc,
          {
            ...resultSupplier,
            updatedAt: bugetResponse?.updatedAt
              ? TimestampToDateTime(bugetResponse?.updatedAt) : t('Sem resposta'),
            response: budget.responses,
            observation: bugetResponse?.observation,
          }];
      }

      return acc;
    }, []);

    setSuppliersData(supplierData);
  };

  const handleSuppliersMinOrder = () => {
    const suppliers = {};
    const notEnoughBought = [];

    if (budget) {
      budget.products.forEach((product) => {
        if (product.selected) {
          suppliers[product.selected.supplier.id] = !suppliers[product
            .selected.supplier.id] ? product
              .selected
              .priceTotalValue : suppliers[product.selected.supplier.id] + product
              .selected.priceTotalValue;
        }
      });

      suppliersData.forEach((s) => {
        if (suppliers[s.id] < numberToFloat(s.minimumValue)) {
          notEnoughBought
            .push({
              name: s.name,
              amount: numberToFloat(s.minimumValue) - suppliers[s.id],
            });
        }
      });
    }

    return notEnoughBought.length > 0
      ? setSuppliersMinVal(notEnoughBought) : setSuppliersMinVal(null);
  };

  useEffect(() => {
    const getDataInit = async () => {
      try {
        const budgetStatus = history?.location?.state?.status;
        const hasIdMainBudget = history?.location?.state?.idMainBudget;
        const isFranchise = user?.type === USER_ROLES.franchise;
        const isAdmin = user?.type === USER_ROLES.admin;
        setIsParent(history?.location?.state?.isParent);
        let restaurantId = user?.restaurant?.id;
        const mainBudgetResponses = [];

        if (history.location?.state?.franchiseesRestaurantView) {
          setFranchiseesRestaurantView(true);
        }

        if (history.location?.state?.type) {
          setType(history.location?.state?.type);
        }

        if (history.location?.state?.idMainBudget) {
          const mainBudget = await getBudgetById({
            budgetId: history.location?.state?.idMainBudget,
          });

          setMainRestaurantId(mainBudget.restaurantId);
          setMainBudgetId(history.location?.state?.idMainBudget);
          setMainBudgetStatus(mainBudget.status);

          if (isFranchise && mainBudget.status === BUDGET_STATUS.FINALIZED) {
            const budgetResponses = await getBudgetResponsesByBudgetId(
              history.location.state.idMainBudget,
            );
            mainBudgetResponses.push(...(budgetResponses || []));
          }
        }

        if (isAdmin) {
          const { restaurantId: paramsRestaurantId } = params;
          restaurantId = paramsRestaurantId;
        }

        let fetchBudget;

        if (isFranchise && budgetStatus === BUDGET_STATUS.REQUESTED) {
          restaurantId = user.restaurant.parent;

          fetchBudget = await getBudgetByIdWithResponse(
            params.id,
            restaurantId,
          );
        } else if (
          (isFranchise
          && budgetStatus !== BUDGET_STATUS.REQUESTED
          && hasIdMainBudget) || history?.location?.state?.isParent
        ) {
          restaurantId = history?.location?.state?.restaurantId || user?.restaurant?.id;

          fetchBudget = await getChildBudgetByIdWithResponse({
            budgetId: params.id,
            restaurantId,
          });
        } else {
          fetchBudget = await getBudgetByIdWithResponse(
            params.id,
            restaurantId,
            isFranchise,
          );
        }

        const loadAlertClickChangeSupplier = await localStorage.getItem('alertClickChangeSupplier');
        setAlertClickChangeSupplier(!loadAlertClickChangeSupplier);

        const fetchRestaurant = await getRestaurantByID(restaurantId);
        setRestaurant(fetchRestaurant);

        const fetchSuppliers = (await getSuppliers()).reduce((suppliers, supplier) => ({
          ...suppliers,
          [supplier.id]: supplier,
        }), {});

        const childBudgetResponse = (mainBudgetResponses || [])?.map(response => ({
          ...response,
          products: response.products.map((productResponse) => {
            const currentProduct = fetchBudget
              ?.budget
              ?.products
              ?.find(budgetProduct => budgetProduct.productId === productResponse.id);

            if (!currentProduct) {
              return productResponse;
            }

            return {
              ...productResponse,
              priceTotal: numberToCurrency(
                numberToFloat(currentProduct.quantity)
                * numberToFloat(productResponse.unitPrice),
              ),
            };
          }),
        }));

        const budgetResponses = isFranchise && childBudgetResponse.length
          ? childBudgetResponse
          : fetchBudget.responses;

        const responseProduct = budgetResponses.reduce((allProducts, response) => ({
          ...allProducts,
          ...response.products.reduce((products, product) => ({
            ...products,
            [product.id]: [
              ...(products[product.id] || []),
              {
                idSupplier: response.idSupplier,
                supplier: fetchSuppliers[response.idSupplier],
                priceTotalValue: numberToFloat(product.priceTotal),
                response,
                ...product,
              },
            ],
          }), allProducts),
        }), {});

        const childBudget = await getChildBudget({
          filters: [
            { field: 'idMainBudget', type: '==', value: params.id },
            { field: 'status', type: '==', value: BUDGET_STATUS.SENT },
          ],
        });
        setChildBudgets(childBudget);
        const formattedProducts = childBudget.reduce((acc, current) => {
          current.products.forEach((item) => {
            const findProductIndex = acc.findIndex(product => product.productId === item.productId);

            if (findProductIndex > -1) {
              acc[findProductIndex] = {
                ...acc[findProductIndex],
                quantity: numberToCurrency(
                  numberToFloat(acc[findProductIndex].quantity) + numberToFloat(item.quantity),
                ),
                observation: `${acc[findProductIndex].observation}, ${item.observation ? `${item.observation}` : ''}`,
              };
              return;
            }
            acc.push(item);
          });
          return [
            ...acc,
          ];
        }, []);

        const products = fetchBudget.budget.products.map((product) => {
          const findProduct = formattedProducts.find(
            current => current.productId === product.productId,
          );

          return {
            ...product,
            quantity: findProduct?.quantity || product.quantity,
            responses: responseProduct[product.productId] || [],
            ...(responseProduct[product.productId] || [])
              .reduce((selectedProduct, currentProduct) => {
                const priceLower = selectedProduct?.lower?.priceTotalValue;
                const lower = (priceLower && priceLower < currentProduct.priceTotalValue)
                  ? selectedProduct.lower : currentProduct;

                const priceHigh = selectedProduct?.high?.priceTotalValue || 0;
                const high = (priceHigh < currentProduct.priceTotalValue)
                  ? currentProduct : selectedProduct.high;

                const currentSelected = product.selected?.idSupplier
                  ? selectedProduct.selected
                  : null;

                const selected = (product.selected?.idSupplier === currentProduct.idSupplier)
                  ? currentProduct : currentSelected;

                return {
                  selected: (product.selected?.idSupplier === -1
                    ? product.selected
                    : selected || lower),
                  high,
                  lower,
                };
              }, { selected: null, lower: null, high: null }),
          };
        });

        setBudget({
          ...fetchBudget.budget,
          products,
          responses: budgetResponses,
        });
        setLoading(false);
      } catch (e) {
        if (history.location?.state?.status === 'open' && user?.type === USER_ROLES.franchise) {
          history.push(`/${t('orcamentos')}`);

          Alert({
            title: t('Indisponível'),
            text: t('Você não respondeu esta solicitação.'),
            type: 'info',
          });
          return;
        }

        history.push(`/${t('orcamentos')}`);

        if (e instanceof Exceptions.RenderException) {
          Alert({
            title: t('Erro'),
            text: t(e.message),
            type: 'error',
          });
          return;
        }

        Alert({
          title: t('Erro'),
          text: t('Ocorreu um erro inesperado.'),
          type: 'error',
        });
      }
    };

    if (user) {
      getDataInit();
    }
  }, [user]);

  useEffect(() => {
    if (budget) {
      getSuppliersAndBudget();

      const filteredBudgets = budget?.products
        ?.filter(item => item.selected?.idSupplier !== -1) || [];

      const dataTotal = filteredBudgets.reduce((totalAcc, product) => {
        if (!product?.selected) {
          return totalAcc;
        }
        return {
          total: product?.selected?.priceTotalValue + totalAcc?.total,
          lower: product?.lower?.priceTotalValue + totalAcc?.lower,
          high: product?.high?.priceTotalValue + totalAcc?.high,
          diff: (product?.selected.priceTotalValue - product?.lower?.priceTotalValue)
            + totalAcc?.diff,
          economize: (product?.high?.priceTotalValue - product?.selected?.priceTotalValue)
            + totalAcc?.economize,
          suppliers: totalAcc?.suppliers.add(product?.selected?.idSupplier),
        };
      }, {
        total: 0,
        lower: 0,
        high: 0,
        diff: 0,
        economize: 0,
        suppliers: new Set(),
      });

      setTotal({
        total: numberToCurrency(dataTotal?.total),
        suppliers: dataTotal?.suppliers?.size,
        lower: numberToCurrency(dataTotal?.lower),
        diff: numberToCurrency(dataTotal?.diff),
        high: numberToCurrency(dataTotal?.high),
        economize: numberToCurrency(dataTotal?.economize),
      });
    }
  }, [budget]);

  useEffect(() => {
    handleSuppliersMinOrder();
  }, [budget, suppliersData]);


  const LabelTable = (label, color) => (
    <span style={{ color }}>{label}</span>
  );

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const infoDataFields = [
    {
      label: 'Data limite',
      value: moment(new Date(budget.endsAt.seconds * 1000)).format('DD/MM/YYYY HH:mm'),
      grid: { xs: 12, md: 6 },
    },
    {
      label: 'Horário de entrega',
      value: budget.deliveryTime,
      grid: { xs: 12, md: 6 },
    },
    {
      label: 'Observação',
      value: budget.observation,
      grid: { xs: 12 },
    },
  ];

  const infoData = {
    title: budget.name,
    fields: infoDataFields,
  };

  const handleNameAlert = (name, supplier) => {
    if (user?.type === USER_ROLES.franchise) {
      return name;
    }
    if (suppliersMinVal && supplier) {
      const suppliers = suppliersMinVal.map(item => item.name);

      if (suppliers.includes(supplier)) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ErrorIcon style={{ marginRight: '5px' }} color="error" />
            {name}
          </div>
        );
      }
    }

    if (childDeficitSuppliers.find(s => s.name === supplier && s.product.includes(name))) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ErrorIcon style={{ marginRight: '5px' }} color="error" />
          {name}
        </div>
      );
    }

    return name;
  };

  const productData = budget.products.map((product, index) => ({
    index,
    id: product.productId,
    name: handleNameAlert(product.productName, product.selected?.supplier?.name),
    observation: product.selected?.observation || product?.observation,
    supplier: LabelTable(
        product.selected?.supplier?.name || 'Sem cotações',
        product.selected ? null : palette.danger.dark,
    ),
    price: LabelTable(
        product.selected?.unitPrice,
        product.selected?.unitPrice !== product.lower?.unitPrice
          ? palette.danger.dark : palette.success.dark,
    ),
    priceValue: product.selected?.unitPrice || 0,
    totalPrice: product.selected?.priceTotal || 0,
    priceTotalValue: product.selected?.priceTotalValue || 0,
    supplierValue: product.selected?.supplier?.name || '',
    quantity: product.unit ? `${product.quantity} (${product.unit})` : product.quantity,
    lowestPrice: product.selected?.idSupplier !== -1 ? product.lower?.priceTotal : '',
    highPrice: product.high?.priceTotal,
    diffPrice: product.selected?.idSupplier !== -1 ? numberToCurrency(
        product.selected?.priceTotalValue - product.lower?.priceTotalValue || 0,
    ) : numberToCurrency(0),
    quotation: product.selected,
    paymentTerm: product.selected?.response?.paymentTerm,
    deadline: product.selected?.response?.deadline,
    validity: product.selected?.response?.quotationValidity,
    ref: product,
  }));

  const handleCloseBtn = () => {
    if (!franchiseesRestaurantView || user.type === USER_ROLES.franchise) {
      return history.push(`/${t('orcamentos')}`);
    }

    setFranchiseesRestaurantView(false);

    const state = {
      idMainBudget: mainBudgetId,
      mainBudgetRestaurantId: mainRestaurantId,
      franchiseesRestaurantView: false,
    };

    if (type === t('editar')) {
      return history.push({
        pathname: `/${t('orcamentos')}/${t('editar')}/${mainBudgetId}/${t('restaurantes-associados')}`,
        state,
      });
    }

    history.push({
      pathname: `/${t('orcamento-resposta')}/${mainBudgetId}/${t('restaurantes-associados')}`,
      state,
    });

    return history.go(0);
  };

  const baseUrl = `/${t('orcamento-resposta')}/${budget.id}`;

  const OrderBudgetComponent = ({ component: Component }) => (
    <Component
      t={t}
      params={params}
      classes={classes}
      history={history}
      infoData={infoData}
      productData={productData}
      budget={budget}
      setBudget={setBudget}
      mainBudgetId={mainBudgetId}
      mainBudgetStatus={mainBudgetStatus}
      total={total}
      setTotal={setTotal}
      restaurant={restaurant}
      setRestaurant={setRestaurant}
      alertClickChangeSupplier={alertClickChangeSupplier}
      setAlertClickChangeSupplier={setAlertClickChangeSupplier}
      handleCancel={() => history.push(`/${t('orcamentos')}`)}
      loading={loading}
      setLoading={setLoading}
      user={user}
      childBudgets={childBudgets}
      minOrder={suppliersMinVal}
      childHasDeficit={childHasDeficit}
    />
  );

  return (
    <>
      <Tabs
        btnCloseShow={false}
        components={[
          {
            name: 'Geral',
            route: baseUrl,
            screen: (user.type === USER_ROLES.franchise
                && mainBudgetStatus !== BUDGET_STATUS.FINALIZED) || isParent
              ? (<OrderBudgetComponent component={OrderChildBudgetReportGeneral} />)
              : (<OrderBudgetComponent component={OrderBudgetReportGeneral} />),
          },
          {
            name: 'Fornecedores',
            route: `${baseUrl}/${t('fornecedores')}`,
            screen: <OrderBudgetReportSupliers
              handleCancel={() => history.push(`/${t('orcamentos')}`)}
              t={t}
              params={params}
              classes={classes}
              suppliers={suppliersData}
              infoData={infoData}
              restaurantName={restaurant?.name}
            />,
          },
          {
            name: 'Restaurantes Associados',
            route: `${baseUrl}/${t('restaurantes-associados')}`,
            hidden: !(budget.status === BUDGET_STATUS.OPEN
              || budget.status === BUDGET_STATUS.FINALIZED)
              || franchiseesRestaurantView
              || !(user?.type === USER_ROLES.master),
            screen: (
              <FranchiseesRestaurant
                t={t}
                params={params}
                classes={classes}
                history={history}
                isParent={user?.type === USER_ROLES.master}
                setLoading={setLoading}
                user={user}
                mainBudgetRestaurantId={mainRestaurantId}
                budget={budget}
                suppliersData={suppliersData}
                setChildHasDeficit={setChildHasDeficit}
                setChildDeficitSuppliers={setChildDeficitSuppliers}
              />
            ),
          },
        ]}
      />

      <div className={classes.closeButtonContainer}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleCloseBtn}
        >
          {t('Fechar')}
        </Button>
      </div>
    </>
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(OrderBudgetReport);
