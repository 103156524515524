import React, { useState } from 'react';
import {
  withStyles,
  CircularProgress,
  IconButton,
  Button,
  Collapse,
} from '@material-ui/core';
import { Alert as AlertMUI } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import flow from 'lodash.flow';
import {
  Alert,
  CustomTable,
  CustomForm,
  CustomInfo,
} from 'admin-base-component-library';
import orderData from 'lodash.orderby';
import {
  tableBudgetResponseTotal,
  tableBudgetResponseProducts,
} from './table';
import { formReporter } from './form';
import styles from './styles';
import ChangeSupplierModal from './components/ChangeSupplierModal';
import OrderBudgetReportCreateOrderModal from './components/OrderBudgetReportCreateOrderModal';
import { BUDGET_STATUS, finishBudget, updateBudgetProducts } from '../../services/budget';
import { USER_ROLES } from '../../services/user';
import { useUserPermission } from './permissions';
import { numberToFloat } from '../../services/Service';
import { formatCurrency } from '../../utils/formatters';

const OrderBudgetReportGeneral = ({
  t,
  classes,
  history,
  user,
  infoData,
  productData,
  budget,
  setBudget,
  mainBudgetId,
  mainBudgetStatus,
  total,
  restaurant,
  alertClickChangeSupplier,
  setAlertClickChangeSupplier,
  loading,
  childBudgets,
  minOrder,
  childHasDeficit,
}) => {
  const [selected, setSelected] = useState(false);
  const [createOrder, setCreateOrder] = useState(false);
  const userPermission = useUserPermission();

  const handleSelectProduct = (selectedProduct, product) => {
    if (user.type === USER_ROLES.admin || budget.status !== BUDGET_STATUS.FINALIZED) {
      return;
    }

    setSelected(null);

    Alert({
      title: t('Alterar Fornecedor'),
      text: `${t('Tem certeza que deseja selecionar este fornecedor?')}`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: t('Prosseguir'),
      cancelButtonText: t('Cancelar'),
    }).then((result) => {
      if (result.value) {
        setBudget({
          ...budget,
          products: budget.products.map((currentProduct) => {
            if (currentProduct.productId === selectedProduct.id) {
              return {
                ...currentProduct,
                selected: selectedProduct,
              };
            }
            return currentProduct;
          }),
        });
        return;
      }
      setSelected(product);
    });
  };

  const handleUpdateBudgetProducts = async (showAlert = true) => {
    try {
      const productsToUpdate = budget.products.map((product) => {
        const cleanSelected = product.selected;
        delete cleanSelected?.response?.products;
        return ({
          disabledSuppliers: product.disabledSuppliers,
          observation: product.observation,
          productId: product.productId,
          productName: product.productName,
          quantity: product.quantity,
          segments: product.segments,
          unit: product.unit,
          selected: cleanSelected,
        });
      });
      await updateBudgetProducts({
        budgetId: budget.id,
        products: productsToUpdate,
        isChildBudget: user.type === USER_ROLES.franchise,
      });
      if (showAlert) {
        Alert({
          title: t('Fornecedores salvos'),
          text: t('Fornecedores salvos com sucesso.'),
          type: 'success',
        });
      }
    } catch {
      if (showAlert) {
        Alert({
          title: t('Erro'),
          text: t('Erro ao salvar fornecedores.'),
          type: 'error',
        });
      }
    }
  };

  const handleFinish = () => {
    Alert({
      title: t('Finalizar Orçamento'),
      text: `${t('Tem certeza que deseja finalizar este orçamento? Os fornecedores não poderão mais responder ao orçamento caso ele seja finalizado.')}`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: t('Prosseguir'),
      cancelButtonText: t('Cancelar'),
    }).then(async (result) => {
      if (result.value) {
        try {
          await finishBudget(budget);
          setBudget({
            ...budget,
            status: BUDGET_STATUS.FINALIZED,
          });

          Alert({
            title: t('Orçamento finalizado'),
            text: t('Orçamento finalizado com sucesso'),
            type: 'success',
          });
        } catch (e) {
          Alert({
            title: t('Erro'),
            text: t('Erro ao finalizar orçamento'),
            type: 'error',
          });
        }
      }
    });
  };

  const handleCloseAlert = async () => {
    await localStorage.setItem('alertClickChangeSupplier', true);
    setAlertClickChangeSupplier(false);
    history.push(`/${t('orcamentos')}`);
  };

  const handleEdit = async () => {
    if (budget.status === BUDGET_STATUS.FINALIZED) await handleUpdateBudgetProducts(false);
    const isFranchise = user.type === USER_ROLES.franchise;
    history.push({
      pathname: `/${t('orcamentos')}/${t('editar')}/${budget.id}/${t('produtos')}`,
      state: {
        isEditQuantity: true,
        idMainBudget: mainBudgetId,
        mainBudgetRestaurantId: budget.restaurantId,
        idChildBudget: isFranchise && budget.id,
        status: budget.status,
        isChildBudget: isFranchise,
      },
    });
  };

  if (loading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  const Info = () => <CustomInfo data={infoData} />;

  const AlertMinOrderSupplier = () => (
    <>
      {user?.type !== USER_ROLES.master
        && minOrder
        && minOrder.map(({ name, amount }) => (
          <AlertMUI
            className={classes.alertDanger}
            icon={false}
            variant="filled"
            severity="warning"
          >
            {user?.type !== USER_ROLES.master ? t(`Faltam ${formatCurrency(amount)} para fechar o pedido mínimo da ${name}.`) : t('Verifique na aba de Restaurantes Associados os valores faltantes para o pedido mínimo do fornecedor')}
          </AlertMUI>
        ))
      }
      {
        childHasDeficit && user?.type === USER_ROLES.master && (
          <AlertMUI
            className={classes.alertDanger}
            icon={false}
            variant="filled"
            severity="warning"
          >
            {t('Verifique na aba de Restaurantes Associados os valores faltantes para o pedido mínimo do fornecedor')}
          </AlertMUI>
        )
      }
    </>
  );

  const AlertChangeSupplier = () => (
    <Collapse in={alertClickChangeSupplier}>
      <AlertMUI
        className={classes.alertMui}
        icon={false}
        variant="filled"
        severity="info"
        action={(
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleCloseAlert}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
      )}
      >
        {t('Clique no produto para alterar o fornecedor')}
      </AlertMUI>
    </Collapse>
  );

  const ProductsTable = () => (
    <CustomTable
      tableCells={tableBudgetResponseProducts}
      data={productData}
      sortData={(order, orderBy, values) => {
        if (order) {
          const formattedValues = values.map(value => ({
            ...value,
            diffPriceValue: numberToFloat(value.diffPrice),
          }));

          const fields = {
            supplier: 'supplierValue',
            price: 'priceTotalValue',
            diffPrice: 'diffPriceValue',
          };

          return orderData(formattedValues, [fields[orderBy] || orderBy], [order]);
        }
        return values;
      }}
      pagination={false}
      onClickRow={item => (item.quotation ? setSelected(item) : null)}
      isVertical
      numberedItems
    />
  );

  const Summary = () => (
    <CustomTable
      tableCells={tableBudgetResponseTotal}
      data={[total]}
      pagination={false}
      isVertical
    />
  );

  return (
    <div className={classes.root}>
      <CustomForm
        buttonOkLabel="Enviar"
        fields={formReporter(
          Info,
          Summary,
          AlertChangeSupplier,
          AlertMinOrderSupplier,
          ProductsTable,
        )}
        buttonCancel={false}
        buttonOk={false}
      />
      {user.type !== USER_ROLES.admin && (
        <div className={classes.buttonContainer}>
          {(budget.status === BUDGET_STATUS.FINALIZED
            || mainBudgetStatus === BUDGET_STATUS.FINALIZED)
            && (
              <Button
                color="secondary"
                variant="contained"
                className={classes.button}
                onClick={() => {
                  setCreateOrder(true);
                }}
              >
                {t('Gerar Pedido')}
              </Button>
            )}

          {(budget.status === BUDGET_STATUS.FINALIZED
            || mainBudgetStatus === BUDGET_STATUS.FINALIZED)
            && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => handleUpdateBudgetProducts()}
              >
                {t('Salvar')}
              </Button>
            )}

          {(budget.status === BUDGET_STATUS.OPEN
            || budget.status === BUDGET_STATUS.FINALIZED
            || mainBudgetStatus === BUDGET_STATUS.FINALIZED)
            && user.type !== USER_ROLES.master
            && (
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={handleEdit}
              >
                {t('Editar')}
              </Button>
            )}

          {budget.status === BUDGET_STATUS.OPEN && (
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={handleFinish}
            >
              {t('Finalizar cotação')}
            </Button>
          )}
        </div>
      )}
      <ChangeSupplierModal
        modal={!!selected}
        product={selected}
        closeModal={() => setSelected(false)}
        classes={classes}
        t={t}
        handleSelectProduct={handleSelectProduct}
        userPermission={userPermission}
        budgetStatus={budget.status}
        user={user}
      />

      <OrderBudgetReportCreateOrderModal
        modal={createOrder}
        budget={budget}
        childBudgets={childBudgets}
        closeModal={() => setCreateOrder(false)}
        classes={classes}
        t={t}
        restaurant={restaurant}
      />
    </div>
  );
};

export default flow(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
)(OrderBudgetReportGeneral);
