import { Mask } from 'admin-base-component-library';
import { BUDGET_STATUS } from '../../services/budget';

export const product = (updateProduct, obsIcon, obsAction) => [
  {
    align: 'center',
    name: 'Bloquear',
    key: 'hide',
    type: 'checkbox',
    onChange: (e, row) => updateProduct(e.target.checked, row, 'hide'),
  },
  {
    align: 'left',
    name: 'Nome',
    key: 'productName',
    cellStyle: {
      minWidth: 180,
      maxWidth: 200,
      whiteSpace: 'unset',
      wordBreak: 'break-word',
    },
  },
  {
    align: 'left',
    name: 'Quantidade',
    key: 'unitAndQuantity',
  },
  {
    align: 'obs',
    name: 'Observação',
    key: 'observationRequest',
    cellStyle: {
      minWidth: 180,
      maxWidth: 200,
      whiteSpace: 'unset',
      wordBreak: 'break-word',
    },
  },
  {
    align: 'left',
    name: 'Valor unitário',
    key: 'unitPrice',
    type: 'input',
    onChange: (e, row) => updateProduct(
      Mask.maskCurrencyField(e.target.value), row, 'unitPrice', 'priceTotal',
    ),
    cellStyle: {
      minWidth: 150,
      maxWidth: 180,
    },
  },
  {
    align: 'left',
    name: 'Preço total',
    key: 'priceTotal',
  },
  {
    align: 'center',
    name: 'Observação',
    key: 'observation',
    type: 'action',
    buttons: [''],
    customButtons: [
      {
        icon: obsIcon,
        action: obsAction,
      },
    ],
  },
];

export default [
  {
    align: 'left',
    name: 'Nome do restaurante',
    key: 'restaurantName',
    sortable: true,
  },
  {
    align: 'center',
    name: 'Data limite',
    key: 'endsAt',
    sortable: true,
  },
  {
    align: 'center',
    name: 'Horário de entrega',
    key: 'deliveryTime',
    sortable: true,
  },
  {
    align: 'left',
    name: 'Observação',
    key: 'observation',
    cellStyle: {
      minWidth: 180,
      maxWidth: 200,
      whiteSpace: 'unset',
      wordBreak: 'break-word',
    },
  },
  {
    align: 'center',
    name: 'Ações',
    key: 'actions',
    type: 'action',
    isEditable: item => item.status === BUDGET_STATUS.OPEN,
    buttons: ['edit'],
  },
];
