import { useTranslation } from 'react-i18next';
import { Alert } from 'admin-base-component-library';

const useForm = () => {
  const { t } = useTranslation();
  const validate = (
    schema,
    values,
    callback,
    fieldsArrayOfObject = {},
  ) => {
    schema
      .validate(values, {
        abortEarly: false,
      })
      .then(callback)
      .catch((validationErrors) => {
        const getError = (errors, path) => {
          if (path && /\[\d+\]/.test(path)) {
            const number = Number(path.replace(/[^0-9]+/g, '')) + 1;
            const [fieldName] = path.split('[');
            return fieldsArrayOfObject[fieldName]
              ? `${t(fieldsArrayOfObject[fieldName])} ${number} - ${errors[0]}` : errors[0];
          }
          return errors[0];
        };

        Alert({
          title: t('Erro de validação'),
          text: validationErrors.inner.map(({ errors, path }) => getError(errors, path)).join('<br />'),
          type: 'error',
        });
      });
  };

  return {
    validate,
  };
};

export default useForm;
