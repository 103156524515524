import React from 'react';
import { CustomTable, CustomInfo } from 'admin-base-component-library';
import {
  Modal,
  Fade,
  Backdrop,
  Paper,
  IconButton,
} from '@material-ui/core';
import { Alert as AlertMUI } from '@material-ui/lab';
import {
  Close as IconClose,
} from '@material-ui/icons';
import orderData from 'lodash.orderby';

import { tableBudgetResponseProductsSelect } from '../table';
import { numberToCurrency, numberToFloat } from '../../../services/Service';
import { USER_ROLES } from '../../../services/user';
import { BUDGET_STATUS } from '../../../services/budget';

const ChangeSupplierModal = ({
  t,
  modal,
  classes,
  closeModal,
  product,
  handleSelectProduct,
  userPermission,
  budgetStatus,
  user,
}) => {
  const getData = () => [
    {
      index: -1,
      id: -1,
      supplier: 'Nenhum',
      observation: '',
      price: '0,00',
      diffPrice: '0,00',
      selected: product.ref.selected.idSupplier === -1,
      paymentTerm: '',
      deadline: '',
      validity: '',
      product: {
        ...product.ref.responses[0],
        idSupplier: -1,
        observation: '',
        priceTotal: '0',
        priceTotalValue: 0,
        unitPrice: '',
        supplier: {
          ...product.ref.responses[0].supplier,
          id: -1,
          name: 'Nenhum',
        },
        response: {
          deadline: '',
        },
      },
    },
    ...product.ref.responses.map((currentProduct, index) => ({
      index,
      id: currentProduct.productId,
      supplier: currentProduct.supplier.name,
      observation: currentProduct.observation,
      price: currentProduct.priceTotal,
      diffPrice: numberToCurrency(
        Math.abs(product.ref.selected.priceTotalValue - currentProduct.priceTotalValue),
      ),
      selected: product.ref.selected.idSupplier === currentProduct.idSupplier,
      paymentTerm: currentProduct.response.paymentTerm,
      deadline: currentProduct.response.deadline,
      validity: currentProduct.response.quotationValidity,
      product: currentProduct,
    })),
  ];

  return (
    <Modal
      open={modal}
      className={classes.modalForm}
      onClose={closeModal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <Paper className={classes.modalReport}>
          <div className={classes.modalCloseContaier}>
            <IconButton onClick={closeModal}>
              <IconClose />
            </IconButton>
          </div>

          <CustomInfo
            data={{
              fields: [
                {
                  label: 'Produto',
                  value: typeof product?.name === 'object' ? product?.name?.props.children[1] : product?.name,
                  grid: { xs: 12, md: 6 },
                },
                {
                  label: 'Quantidade',
                  value: product?.quantity,
                  grid: { xs: 12, md: 6 },
                },
                {
                  label: 'Observação',
                  value: product?.observation,
                  grid: { xs: 12 },
                },
              ],
            }}
          />

          <h2 className={classes.title}>{`${t('Produtos')}`}</h2>

          {(user?.type === USER_ROLES.admin || budgetStatus !== BUDGET_STATUS.FINALIZED) && (
          <AlertMUI
            className={classes.alertMui}
            icon={false}
            variant="filled"
            severity="warning"
          >
            {user?.type !== USER_ROLES.admin ? t('A alteração de fornecedores só pode ocorrer em cotações finalizadas.') : t('Usuários administrativos não podem alterar o fornecedor selecionado.')}
          </AlertMUI>
          )}

          {product?.ref?.responses && (
          <CustomTable
            tableCells={tableBudgetResponseProductsSelect(userPermission, budgetStatus)}
            data={getData()}
            sortData={(order, orderBy, values) => {
              if (order) {
                const formattedValues = values?.map(value => ({
                  ...value,
                  priceValue: numberToFloat(value?.price),
                  diffPriceValue: numberToFloat(value?.diffPrice),
                }));

                const fields = {
                  price: 'priceValue',
                  diffPrice: 'diffPriceValue',
                };

                return orderData(formattedValues, [fields[orderBy] || orderBy], [order]);
              }
              return values;
            }}
            pagination={false}
            onClickRow={selected => handleSelectProduct(selected?.product, product)}
            isVertical
          />
        )}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ChangeSupplierModal;
